/* eslint-disable */
import * as actionTypes from "../actions/actions";
import getLength from "../global/getLength";

const initialState = {
  boardTitle: {
    title: "",
    show: false,
  },
  btnHide: true,
  nxtBtn: true,
  input: false,
  message: "",
  tr: null,
  br: null,
  c: null,
  p: null,
  r: null,
  carry: null,
  result: {},

  valueEnter: false,

  correctCarryCell: false,
  correctResultRow: 0,
  correctResultCell: false,

  carryClick: true,
  resultClick: true,
  carryFocused: false,
  resultFocused: false,
  carryAdded: false,
  resultAdded: false,
  carryFocusedFirst: false,

  doubleResult: false,
  dr1Added: false,
  addZero: false,
  zeroToBeAdded: [],
  continueAddingZeroCheck: 0,

  finalResult: false,
  finalResultNext: false,
  finalResultInit: true,
};

const practiceMode = (state = { ...initialState }, action) => {
  const removeHightlightsFromCells = () => {
    document
      .getElementById("carryInputContainer")
      .childNodes.forEach((c) => (c.style.backgroundColor = "transparent"));
    document
      .querySelectorAll("#resultInput")
      .forEach((c) => (c.style.backgroundColor = "transparent"));
  };
  const returnResult = (condition, keyObj, tR) => {
    let returnObj;
    if (condition) {
      state.resultFocused = false;
      let rfrc = {};
      if (state.carryFocusedFirst && !state.carryAdded) {
        rfrc = {
          carryFocused: true,
          carryFocusedFirst: false,
          message: "Correct! Now enter the carry number.",
        };
      } else if (!state.carryAdded && !state.carryFocused) {
        rfrc = {
          message: "Correct! Now select the carry cell.",
        };
      } else if (!tR[state.tr - 1] && !state.dr1Added) {
        rfrc = {
          message: "Correct! Now select the next result row.",
        };
      } else {
        rfrc = {
          message: "Correct!",
        };
      }
      returnObj = {
        result: {
          ...state.result,
          [state.correctResultRow]: keyObj,
        },
        ...rfrc,
      };
    } else {
      returnObj = {
        result: {
          ...state.result,
          [state.correctResultRow]: keyObj,
        },
        message: "Wrong! Try again.",
      };
    }
    return returnObj;
  };

  switch (action.type) {
    case actionTypes.BOARDSWITCH:
      return {
        ...initialState,
      };
    case actionTypes.PRACTICESTART:
      let returnObjStart = {};
      const tR = action.topRow.join("");
      const bR = action.bottomRow.join("");
      const n1 = action.topRow[2];
      const n2 = action.bottomRow[2];
      const ans = n1 * n2;
      const firstCarry = Math.floor(ans / 10);
      let ra = [];
      for (let i = 0; i <= bR.length; i++) {
        ra.push([]);
      }
      if (firstCarry === 0) {
        state.carryClick = false;
        state.carryAdded = true;
      }
      if (getLength(action.bottomRow) === 1 && getLength(action.topRow) === 1) {
        if (firstCarry === 0) {
          returnObjStart = {
            message: `${n1} X ${n2} = ${ans}, here we have ${ans} as answer. We need to write it down. So, first click on which cell the digit will go?`,
          };
        } else {
          returnObjStart = {
            message: `${n1} X ${n2} = ${ans}, here we have ${ans} as answer. We need to write down ${Math.floor(
              ans % 10
            )} first and write down ${firstCarry} after. Click on which cell the first digit will go ?`,
            doubleResult: true,
            carryAdded: true,
            carryClick: false,
          };
        }
      } else {
        returnObjStart = {
          message: `${n1} X ${n2} = ${ans}, here we have ${ans} as answer. We need to write down ${Math.floor(
            ans % 10
          )} ${
            firstCarry === 0
              ? `Click on which cell the answer will go?`
              : `and carry ${firstCarry}. Click on which cell the carry will go ?`
          }`,
        };
      }
      return {
        ...state,
        boardTitle: {
          title: `We will muiltiply <span>${tR}</span> and <span>${bR}</span> step by step together. Our first step will be entering the correct values in the  highlighted area. And then <span>click the next button to continue.</span>`,
          show: true,
        },
        btnHide: false,
        tr: 2,
        br: 2,
        c: 0,
        p: 0,
        r: String(Number(tR) * n2).length - 1,
        input: true,
        correctCarryCell: 4,
        correctResultCell: 5,
        result: { ...ra },
        ...returnObjStart,
      };
    case actionTypes.PRACTICENEXT:
      let returnObjNxt = {};
      removeHightlightsFromCells();
      let t, b, c, p, r, cc, crr, crc;
      t = state.tr;
      b = state.br;
      c = state.c;
      p = state.p;
      r = state.r;
      crr = state.correctResultRow;
      crc = state.correctResultCell;

      const resultRows = action.r.resultsRows;
      const resultRowsLength =
        action.r.bR.filter((c) => {
          if (c !== "") {
            return c;
          }
          return null;
        }).length - 1;

      if (resultRowsLength) {
        const stateResultRows = state.result[resultRowsLength];
        const stateResultRowsLength = stateResultRows?.length;
        if (
          stateResultRows[
            stateResultRowsLength - resultRows[resultRowsLength].length
          ] === resultRows[resultRowsLength][0]
        ) {
          state.finalResult = true;
          state.carryClick = false;
        }
      }
      if (!state.finalResult) {
        if (!state.addZero) {
          if (!action.r.tR[t - 1] && b >= 0) {
            const bRLength = getLength(action.r.bR);
            const tRLength = getLength(action.r.tR);

            if (bRLength === 1) {
              const rLength = getLength(state.result[0]);
              if (
                rLength === bRLength * tRLength + 1 ||
                rLength === bRLength * tRLength
              ) {
                returnObjNxt = {
                  tr: null,
                  br: null,
                  message: `You Have Done It!`,
                };
              }
            } else {
              t = 2;
              b--;
              c = 0;
              p++;
              crr++;
              r = action.r.resultsRows[crr].length - 1;
              crc = 5;
              const zeroToBeAdded = [
                ...Array(action.r.bR.length - 1 - b).fill(true),
              ];
              zeroToBeAdded.push(false);
              returnObjNxt = {
                addZero: true,
                carryAdded: false,
                zeroToBeAdded: zeroToBeAdded,
                message: "Add zeros",
              };
            }
          } else {
            if (c === action.r.tR.length - 1) {
              c = 0;
              p++;
            } else {
              c++;
            }
            t--;
            r--;
            crc--;
            let n11 = action.r.tR[t];
            let n22 = action.r.bR[b];
            let prevCarry = action.r.carries[state.c][state.p];
            let mult = n11 * n22;

            const currentCarry = action.r.carries[c][p];
            if (currentCarry === 0) {
              state.carryClick = false;
              state.carryAdded = true;
            } else if (action.r.tR[t - 1]) {
              state.carryAdded = false;
            }

            let messageVariable = "";
            if (currentCarry === 0) {
              if (prevCarry === 0) {
                messageVariable = `${n11} X ${n22} = ${mult} , here we have ${mult} as result. We need to write it down. So, first click on which cell the result will go?`;
              } else {
                messageVariable = `${n11} X ${n22} = ${mult} , Previously we had carry ${prevCarry} after adding that we have ${mult} + ${prevCarry} = ${
                  mult + prevCarry
                } . So we need to right down ${
                  mult + prevCarry
                }. Please select the cell first where we need to put it.`;
              }
            } else {
              if (prevCarry === 0) {
                messageVariable = `${n11} X ${n22} = ${mult}. So we need to right down ${action.r.resultsRows[crr][r]} and carry ${currentCarry}. Please select the cell first where we need to put them.`;
              } else {
                messageVariable = `${n11} X ${n22} = ${mult} , Previously we had carry ${prevCarry} after adding that we have ${mult} + ${prevCarry} = ${
                  mult + prevCarry
                } . So we need to right down ${
                  action.r.resultsRows[crr][r]
                } and carry ${currentCarry}. Please select the cell first where we need to put them.`;
              }
            }

            if (!action.r.tR[t - 1]) {
              if (currentCarry === 0) {
                returnObjNxt = {
                  message: `${n11} X ${n22} = ${mult} , Previously we had carry ${prevCarry} after adding that we have ${mult} + ${prevCarry} = ${
                    mult + prevCarry
                  } . We don't have anything to carry now. So we need to right down ${
                    action.r.resultsRows[crr][r]
                  }. Please select the cell first where we need to put it.`,
                };
              } else {
                returnObjNxt = {
                  message: `${n11} X ${n22} = ${mult} , Previously we had carry ${prevCarry} after adding that we have ${mult} + ${prevCarry} = ${
                    mult + prevCarry
                  } . We don't have anything to carry now. So we need to right down ${
                    action.r.resultsRows[crr][r]
                  } and also ${currentCarry}. Please select the cell first where we need to put them.`,
                };
              }
              state.carryClick = false;
              state.carryAdded = true;
            } else {
              returnObjNxt = {
                message: messageVariable,
              };
            }
          }
          if (!action.r.tR[t - 1]) {
            if (action.r.result[crr].length !== getLength(action.r.tR)) {
              state.doubleResult = true;
            }
          }
          switch (b) {
            case 2:
              switch (state.c + 1) {
                case 0:
                  cc = 4;
                  break;
                case 1:
                  cc = 3;
                  break;
                case 2:
                  cc = 2;
                  break;
              }
              break;
            case 1:
              switch (state.c + 1) {
                case 0:
                  cc = 3;
                  break;
                case 1:
                  cc = 2;
                  break;
                case 2:
                  cc = 1;
                  break;
              }
              break;
            case 0:
              switch (state.c + 1) {
                case 0:
                  cc = 2;
                  break;
                case 1:
                  cc = 1;
                  break;
                case 2:
                  cc = 0;
                  break;
              }
              break;
          }
        } else {
          let n11 = action.r.tR[t];
          let n22 = action.r.bR[b];
          let mult = n11 * n22;
          r--;
          switch (b) {
            case 2:
              cc = 4;
              break;
            case 1:
              cc = 3;
              break;
            case 0:
              cc = 2;
              break;
          }
          crc--;
          returnObjNxt = {
            message: `${n11} X ${n22} = ${mult}. ${
              action.r.carries[c][p] === 0
                ? `Now, we have`
                : `Now, we have carry ${action.r.carries[c][p]} and`
            } result of ${
              action.r.resultsRows[crr][r]
            }. Please select the cell first.`,
            addZero: false,
          };
        }
      } else {
        const fRI = state.finalResultInit;
        const fR = state.result[resultRowsLength + 1];
        const fRlength = action.r.finalResult.length;
        if (fRI) {
          state.finalResultInit = false;
          crr++;
          crc = 5;
          r = action.r.finalResult.length - 1;
          t = null;
          b = null;
        } else {
          crc--;
          r--;
        }

        if (fR[fR.length - fRlength] === action.r.finalResult[0]) {
          returnObjNxt = {
            message: `You Have Done It!`,
            resultClick: false,
          };
        } else {
          returnObjNxt = {
            message: `Add the final result of ${action.r.finalResult[r]}`,
            nxtBtn: true,
            finalResultNext: false,
          };
        }
      }

      return {
        ...state,
        nxtBtn: true,
        tr: t,
        br: b,
        c: c,
        p: p,
        r: r,
        carry: null,
        correctCarryCell: cc,
        correctResultRow: crr,
        correctResultCell: crc,
        valueEnter: false,
        resultClick: true,
        carryFocused: false,
        resultFocused: false,
        resultAdded: false,
        ...returnObjNxt,
      };
    case actionTypes.PRACTICEINPUT:
      let returnObj, nxtReturn;
      if (!state.finalResult) {
        if (!state.addZero) {
          if (state.carryFocusedFirst && state.resultFocused) {
            state.carryFocused = false;
          }
          if (state.carryFocused || state.resultFocused) {
            if (state.carryFocused) {
              const correctCarry = action.r.carries[state.c][state.p];
              if (action.key === correctCarry) {
                let m = "";
                if (!state.resultFocused) {
                  m =
                    "Correct! Now select the cell for adding the digit that will go down.";
                } else if (!state.resultAdded) {
                  m = "Correct! Now enter the number that will go down.";
                }
                state.carryAdded = true;
                returnObj = {
                  carry: action.key,
                  message: m,
                  carryFocused: false,
                };
              } else {
                returnObj = {
                  carry: action.key,
                  message: "Wrong! Try again.",
                };
              }
            } else if (state.resultFocused) {
              if (!state.doubleResult) {
                let keyObj = [...state.result[state.correctResultRow]];
                keyObj[state.correctResultCell] = action.key;
                const correctResult =
                  action.r.resultsRows[state.correctResultRow][state.r];
                returnObj = returnResult(
                  action.key === correctResult,
                  keyObj,
                  action.r.tR
                );
                if (action.key === correctResult) state.resultAdded = true;
              } else {
                if (!state.dr1Added) {
                  let keyObj = [...state.result[state.correctResultRow]];
                  keyObj[state.correctResultCell] = action.key;
                  const correctResult =
                    action.r.resultsRows[state.correctResultRow][state.r];
                  returnObj = returnResult(
                    action.key === correctResult,
                    keyObj,
                    action.r.tR
                  );
                  if (action.key === correctResult) {
                    state.dr1Added = true;
                    state.resultClick = true;
                    state.correctResultCell = --state.correctResultCell;
                    state.r = --state.r;
                  }
                } else {
                  let keyObj = [...state.result[state.correctResultRow]];
                  keyObj[state.correctResultCell] = action.key;
                  const correctResult =
                    action.r.resultsRows[state.correctResultRow][state.r];
                  returnObj = returnResult(
                    action.key === correctResult,
                    keyObj,
                    action.r.tR
                  );
                  if (action.key === correctResult) {
                    state.doubleResult = false;
                    state.dr1Added = false;
                    state.resultAdded = true;
                  }
                }
              }
            }
            if (state.carryAdded && state.resultAdded) {
              state.carryClick = true;
              setTimeout(() => {
                action.asyncDispatch({
                  type: actionTypes.PRACTICENEXT,
                  r: action.r,
                });
              }, 500);
            }
          } else if (state.carryClick && !state.resultClick) {
            action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
            returnObj = {
              message: "Please select the carry cell.",
            };
          } else if (!state.carryClick && state.resultClick) {
            action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
            returnObj = {
              message: "Please select the result cell.",
            };
          } else {
            action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
            returnObj = {
              message: "Please select the cells first!",
            };
          }
          //else if (state.carryAdded && state.resultAdded) {
          //  action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
          //  returnObj = {
          //    message:
          //      "You have done it please click the next button to continue.",
          //  };
          //}
        } else {
          if (state.zeroToBeAdded[state.continueAddingZeroCheck]) {
            if (state.resultFocused) {
              let keyObj = [...state.result[state.correctResultRow]];
              keyObj[state.correctResultCell] = action.key;
              if (action.key === 0) {
                let cazc = state.continueAddingZeroCheck;
                returnObj = {
                  result: {
                    ...state.result,
                    [state.correctResultRow]: keyObj,
                  },
                  resultFocused: false,
                  message: "Correct!",
                };
                if (!state.zeroToBeAdded[state.continueAddingZeroCheck + 1]) {
                  setTimeout(() => {
                    action.asyncDispatch({
                      type: actionTypes.PRACTICENEXT,
                      r: action.r,
                    });
                  }, 500);
                } else {
                  nxtReturn = {
                    resultClick: true,
                    correctResultCell: state.correctResultCell - 1,
                    r: state.r - 1,
                    continueAddingZeroCheck: cazc + 1,
                  };
                }
              } else {
                returnObj = {
                  result: {
                    ...state.result,
                    [state.correctResultRow]: keyObj,
                  },
                  message: "Wrong! Try again.",
                };
              }
            } else {
              action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
              returnObj = {
                message: "Please select the cells first!",
              };
            }
          } else {
            action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
            returnObj = {
              message:
                "You have done it please click the next button to continue.",
            };
          }
        }
      } else {
        if (state.finalResultNext) {
          action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
          returnObj = {
            message:
              "You have done it please click the next button to continue.",
          };
        } else {
          if (state.resultFocused) {
            let keyObj = [...state.result[state.correctResultRow]];
            keyObj[state.correctResultCell] = action.key;
            const correctResult = action.r.finalResult[state.r];
            if (action.key === correctResult) {
              returnObj = {
                result: {
                  ...state.result,
                  [state.correctResultRow]: keyObj,
                },
                resultFocused: false,
                message: "Correct!",
                resultClick: true,
                finalResultNext: true,
              };
              setTimeout(() => {
                action.asyncDispatch({
                  type: actionTypes.PRACTICENEXT,
                  r: action.r,
                });
              }, 500);
            } else {
              returnObj = {
                result: {
                  ...state.result,
                  [state.correctResultRow]: keyObj,
                },
                message: "Wrong! Try again.",
              };
            }
          } else {
            action.asyncDispatch({ type: actionTypes.KEYPADHIDE });
            returnObj = {
              message: "Please select the cells first!",
            };
          }
        }
      }
      return {
        ...state,
        ...returnObj,
        ...nxtReturn,
      };
    case actionTypes.PRACTICEMESSAGE:
      return {
        ...state,
        message: action.m,
      };
    case actionTypes.PCF:
      if (!state.resultFocused && !state.resultAdded) {
        state.carryFocusedFirst = true;
      }
      return {
        ...state,
        carryFocused: action.v,
        carryClick: !action.v,
      };
    case actionTypes.PRF:
      return {
        ...state,
        resultFocused: action.v,
        resultClick: !action.v,
      };
    case actionTypes.PRACTICEEND:
      removeHightlightsFromCells();
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default practiceMode;
