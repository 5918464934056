import * as actionTypes from "../actions/actions";

const initialState = {
  sideMenuToggle: false,
  pheader: true,
  pheadermobile: false,
  grid: true,
  carry: true,
  carryMobile: false,
  modes: {
    learn: true,
    practice: false,
    exam: false,
  },
  gridCellSizes: {
    learn: 1,
    practice: 1,
    exam: 1,
  },
};
const modes = {
  learn: false,
  practice: false,
  exam: false,
};
const sidemenu = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETSAVEDUSERSETTINGS:
      return {
        ...state,
        ...action.settings,
        sideMenuToggle: false,
      };
    case actionTypes.SIDEMENUTOGGLE:
      return {
        ...state,
        sideMenuToggle: !state.sideMenuToggle,
      };
    case actionTypes.SIDEMENUCLOSE:
      return {
        ...state,
        sideMenuToggle: false,
      };
    case actionTypes.PHEADERSTOGGLE:
      return {
        ...state,
        pheader: !state.pheader,
        pheadermobile: !state.pheadermobile,
      };
    case actionTypes.GRIDTOGGLE:
      return {
        ...state,
        grid: !state.grid,
      };
    case actionTypes.CARRYTOGGLE:
      return {
        ...state,
        carry: !state.carry,
        carryMobile: !state.carryMobile,
      };
    case actionTypes.BOARDSWITCH:
      return {
        ...state,
        modes: {
          ...modes,
          [action.mod]: true,
        },
      };
    case actionTypes.INCREASEGRIDSIZE:
      let returnedObjIn = {};
      if (state.gridCellSizes[action.grid] < 1) {
        returnedObjIn = {
          gridCellSizes: {
            ...state.gridCellSizes,
            [action.grid]: state.gridCellSizes[action.grid] + 0.01,
          },
        };
      }
      return {
        ...state,
        ...returnedObjIn,
      };
    case actionTypes.DECREASEGRIDSIZE:
      let returnedObjDe = {};
      if (state.gridCellSizes[action.grid] > 0.5) {
        returnedObjDe = {
          gridCellSizes: {
            ...state.gridCellSizes,
            [action.grid]: state.gridCellSizes[action.grid] - 0.01,
          },
        };
      }
      return {
        ...state,
        ...returnedObjDe,
      };
    default:
      return state;
  }
};

export default sidemenu;
