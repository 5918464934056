import * as actionTypes from "../actions/actions";

const initialState = {
  answer: { carries: [] },
  emptyCarry: false,
};

const multiplication = (state = initialState, action) => {
  if (
    action.type === actionTypes.START ||
    action.type === actionTypes.PRACTICESTART ||
    action.type === actionTypes.UPDATEDIGITS
  ) {
    function getArr(input) {
      return input.filter((c) => typeof c === "number");
    }
    let tR = getArr(action.topRow);
    let bR = getArr(action.bottomRow);
    let allProducts = [];
    let allResults = [];
    let product = "";
    let carries = [];

    for (let i = bR.length - 1; i >= 0; i--) {
      let d2 = bR[i];
      let carry = 0;
      for (let j = tR.length - 1; j >= 0; j--) {
        let d1 = tR[j];
        let mult = d2 * d1 + carry;
        carry = Math.floor(mult / 10);
        carry !== null ? carries.push(carry) : carries.push(0);
        product = (mult % 10) + product;
      }
      if (carry % 10 !== 0) product = (carry % 10) + product;
      let shift = 10 ** (bR.length - i - 1);
      allResults.push(
        product * shift === 0
          ? product + String(shift).slice(1)
          : product * shift
      );
      allProducts.push(product);
      product = "";
    }

    const finalResult = String(allResults.reduce((a, c) => a + c))
      .split("")
      .map((num) => {
        return Number(num);
      });
    let rows = [];
    for (var i = 0; i < bR.length; i++) {
      rows[i] = String(allProducts[i])
        .split("")
        .map((num) => {
          return Number(num);
        });
    }
    let resultsRows = [];
    for (var r = 0; r < bR.length; r++) {
      resultsRows[r] = String(allResults[r])
        .split("")
        .map((num) => {
          return Number(num);
        });
    }
    let c = [];
    for (let j = 0; j < tR.length; j++) {
      let k = j;
      let t = [];
      for (let i = 0; i < tR.length; i++) {
        t.push(carries[k]);
        k = k + tR.length;
      }
      c.push(t);
    }

    let checkAllTheCarries = false;
    if ([...new Set(carries)].length === 1 && [...new Set(carries)][0] === 0) {
      checkAllTheCarries = true;
    }

    return {
      ...state,
      answer: {
        ...state.answer,
        tR: action.topRow,
        bR: action.bottomRow,
        carries: c,
        result: rows,
        resultsRows: resultsRows,
        finalResult: finalResult,
      },
      emptyCarry: checkAllTheCarries,
    };
  } else {
    return state;
  }
};

export default multiplication;
