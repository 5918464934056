import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, compose, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";

import numbers from "./store/reducers/numbers";
import learnMode from "./store/reducers/learnMode";
import multiplication from "./store/reducers/multiplication";
import numberPad from "./store/reducers/numberPad";
import sidemenu from "./store/reducers/sidemenu";
import practiceMode from "./store/reducers/practiceMode";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const asyncDispatchMiddleware = (store) => (next) => (action) => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach((a) => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

  const res = next(actionWithAsyncDispatch);

  syncActivityFinished = true;
  flushQueue();

  return res;
};

const rootReducer = combineReducers({
  number: numbers,
  lMode: learnMode,
  multi: multiplication,
  numpad: numberPad,
  menu: sidemenu,
  pMode: practiceMode,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(asyncDispatchMiddleware))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
export default store;
