import * as actionTypes from "../actions/actions";

const initialState = {
  toggleKeypad: false,
  touchTable: false,
};
const numberPad = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.KEYPADTOGGLE:
      return {
        ...state,
        toggleKeypad: !state.toggleKeypad,
      };
    case actionTypes.KEYPADHIDE:
      return {
        ...state,
        toggleKeypad: false,
      };
    case actionTypes.KEYPADSHOW:
      return {
        ...state,
        toggleKeypad: true,
      };
    case actionTypes.PRACTICESTART:
      return {
        ...state,
        toggleKeypad: true,
      };
    case actionTypes.TOUCHTABLE:
      return {
        ...state,
        touchTable: !state.touchTable,
      };
    default:
      return state;
  }
};

export default numberPad;
