import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import * as reduxFunctions from "./store/actions/index";
import { Routes, Route, useLocation } from "react-router-dom";
import Loader from "./Frontend/Loader/Loader";
import Private from "./store/routing/private";

const Welcome = React.lazy(() => import("./Frontend/Welcome/Welcome"));
const Layout = React.lazy(() => import("./containers/Layout/Layout"));
const Signup = React.lazy(() => import("./Frontend/Signup/Signup"));
const Login = React.lazy(() => import("./Frontend/Login/Login"));
const Forget = React.lazy(() => import("./Frontend/Forget/Forget"));
const Reset = React.lazy(() => import("./Frontend/Reset/Reset"));
const Profile = React.lazy(() => import("./Frontend/Profile/Profile"));
const Verify = React.lazy(() => import("./Frontend/VerifyEmail/Verify"));

function App({ setCheckAuth }) {
  const location = useLocation();
  useEffect(() => {
    if (`${location.pathname}${location.search}${location.hash}` === "/app") {
      setCheckAuth();
    }
  }, [setCheckAuth, location]);
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<Private />}>
          <Route exact path="/app" element={<Layout />} />
        </Route>
        <Route exact path="/" element={<Welcome />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<Forget />} />
        <Route path="/reset-password" element={<Reset />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/active-account" element={<Verify />} />
      </Routes>
    </Suspense>
  );
}

const mapDispatchtoProps = (dispatch) => {
  return {
    setCheckAuth: () => dispatch(reduxFunctions.setCheckAuth()),
  };
};
export default connect(null, mapDispatchtoProps)(App);
