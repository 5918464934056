import * as actionTypes from "../actions/actions";
import getLength from "../global/getLength";

const initialState = {
  t: null,
  b: null,
  tF: null,
  bF: null,
  c: null,
  p: 0,
  // Carry Position System
  cp: 0,
  cps: [0, 1, 2], // Forward Position
  cpsr: [1, 2, 3], // Reverse Position
  cpsr2: [1, 2], // 2 digits Reverse Position
  zero: false,
  deleteTwo: false,
  products: {},
  productArray: [],
  ntxDisable: false,
  prevDisable: true,
  finallResultCount: false,
  finallResults: [],
  finallResultCarries: [],
  finallResultCarry: 0,
  fC: 0,
  tutorial: false,
  tutorialCount: 0,
  explanations: [],
  explanationCount: 0,
  explanationsPageCount: 0,
  explanationsZeros: 0,
  explanationsZeroCount: 0,
  nextInfo: false,
  // T13 going forward and backward
  trnp: 0,
  brnp: 0,
  printData: {},
  goingPrev: false,
  // Set first time two digits statement
  sfttd: true,
  // Check For Digit Update if bottom is bigger than top
  udc: false,
  // Check For if it is next page of steps tutorial
  cnpfst: false,
  // Check For if we are going forward or backward
  goingForward: true,
};

const result = (state = { ...initialState }, action) => {
  if (action.type === actionTypes.END) {
    return {
      ...initialState,
    };
  }
  if (action.type === actionTypes.SETPRINTDATA) {
    return {
      ...state,
      printData: action.data,
    };
  }
  if (action.type === actionTypes.SFTTD) {
    return {
      ...state,
      sfttd: action.data,
    };
  }
  if (action.type === actionTypes.START) {
    let tR = action.topRow.filter((c) => typeof c === "number");
    let bR = action.bottomRow.filter((c) => typeof c === "number");
    let expl = [];
    expl.push(1);
    for (let i = 0; i < action.topRow.length - 2; i++) {
      expl.push(2);
    }
    if (tR.length >= 3) {
      expl.push(3);
    }
    if (tR.length === 2) {
      expl[1] = 3;
    }
    if (tR.length === 2 && bR.length === 1) {
      expl[1] = 2;
    }
    if (tR.length === 1) {
      expl = [];
      expl[0] = 1;
    }
    if (tR.length === 1 && bR.length === 1) {
      const calc = String(Number(tR.join("")) * Number(bR.join(""))).split("");
      let calcArray = [];
      if (calc[1]) {
        calcArray = [calc[1], calc[0]];
      } else {
        calcArray = [calc[0]];
      }
      return {
        ...state,
        ntxDisable: true,
        prevDisable: true,
        products: { 0: calcArray },
      };
    } else {
      return {
        ...state,
        explanations: expl,
        t: tR.length - 1,
        b: bR.length - 1,
        tF: 2,
        bF: 2,
        tutorial: true,
      };
    }
  }
  if (action.type === actionTypes.ENABLEBTNS) {
    return {
      ...state,
      ntxDisable: false,
      prevDisable: false,
    };
  }
  if (action.type === actionTypes.DISABLEBTNS) {
    return {
      ...state,
      ntxDisable: true,
      prevDisable: true,
    };
  }
  if (action.type === actionTypes.TUTORIALFORWARDER) {
    return {
      ...state,
      tutorialCount: state.tutorialCount + action.countTime,
    };
  }
  if (action.type === actionTypes.UPDATEDDIGITSCHECK) {
    return {
      ...state,
      udc: true,
    };
  }

  if (action.type === actionTypes.NEXT) {
    state.goingForward = true;
    let stepReturnObject;
    let tR = action.resultDigits.tR.filter((c) => typeof c === "number");
    let bR = action.resultDigits.bR.filter((c) => typeof c === "number");
    state.nextInfo = false;
    if (!state.tutorial) {
      if (
        state.explanationCount === state.explanations.length - 1 &&
        state.b === 0 &&
        state.explanationsPageCount ===
          state.explanations[state.explanationCount]
      ) {
        state.finallResultCount = true;
        state.zero = true;
      }
      if (!state.finallResultCount) {
        function getProduct(c, p) {
          let mult, productArray, products;
          let pA = [...state.productArray];
          if (state.t === tR.length - 1) {
            mult = tR[state.t] * bR[state.b];
          } else {
            mult =
              tR[state.t] * bR[state.b] + action.resultDigits.carries[c][p];
          }
          if (state.t === 0) {
            pA.push(
              mult % 10,
              Math.floor(mult / 10) ? Math.floor(mult / 10) : null
            );
            state.deleteTwo = true;
          } else {
            pA.push(mult % 10);
            state.deleteTwo = false;
          }
          productArray = pA;
          products = {
            ...state.products,
            [p]: pA,
          };
          return {
            productArray,
            products,
          };
        }

        if (state.tF === null && state.bF === null) {
          state.tF = 2;
          state.bF = 2;
        }

        if (!state.zero) {
          if (
            state.explanationCount === 1 &&
            state.explanationsPageCount === 2 &&
            tR.length === 2
          ) {
            stepReturnObject = {
              c: null,
            };
          } else if (
            state.explanationCount === 0 &&
            state.explanationsPageCount === 0 &&
            tR.length === 1
          ) {
            stepReturnObject = {
              ...getProduct(null, state.p),
            };
          } else if (
            state.explanationCount === 0 &&
            state.explanationsPageCount === 0 &&
            tR.length >= 2
          ) {
            stepReturnObject = {
              c: 0,
              ...getProduct(null, state.p),
            };
          } else if (
            state.explanationCount === 1 &&
            state.explanationsPageCount === 0
          ) {
            stepReturnObject = {
              ...getProduct(0, state.p),
            };
          } else if (
            state.explanationCount === 2 &&
            state.explanationsPageCount === 0
          ) {
            stepReturnObject = {
              ...getProduct(1, state.p),
            };
          } else if (
            state.explanationCount === 2 &&
            state.explanationsPageCount === 2
          ) {
            stepReturnObject = {
              c: null,
            };
          } else if (
            state.explanationCount === 1 &&
            state.explanationsPageCount === 1
          ) {
            stepReturnObject = {
              cp: state.cp + 1,
              c: 1,
            };
          } else if (
            state.explanationCount === 2 &&
            state.explanationsPageCount === 2
          ) {
            stepReturnObject = {
              cp: state.cp + 1,
              c: null,
            };
          }
        }

        if (!state.zero) {
          if (
            state.explanationsPageCount ===
            state.explanations[state.explanationCount]
          ) {
            if (
              state.explanationCount === state.explanations.length - 1 &&
              !state.finallResultCount
            ) {
              state.zero = true;
              state.explanationsZeroCount = 0;
            }
            if (
              state.explanationCount !== state.explanations.length - 1 &&
              !state.finallResultCount &&
              !state.zero
            ) {
              state.explanationCount++;
              state.t = state.t - 1;
              state.tF = state.tF - 1;
            }
            if (!state.finallResultCount) state.explanationsPageCount = 0;
          } else {
            if (!state.zero) state.explanationsPageCount++;
          }
        } else {
          if (state.explanationsZeroCount === state.explanationsZeros) {
            state.zero = false;
            state.explanationCount = 0;
            state.explanationsPageCount = 0;
          } else {
            state.explanationsZeroCount++;
          }
        }

        if (state.zero && state.explanationsZeroCount === 0) {
          state.b = state.b - 1;
          state.t = tR.length - 1;
          state.tF = 2;
          state.bF = state.bF - 1;
          state.p = state.p + 1;
          state.cp = state.cps[state.p];
          let pA, products;
          pA = [...Array(state.p).fill(0)];
          products = {
            ...state.products,
            [state.p]: pA,
          };
          stepReturnObject = {
            productArray: pA,
            products,
          };
        }

        if (
          state.explanationCount === state.explanations.length - 1 &&
          state.explanationsPageCount ===
            state.explanations[state.explanationCount] &&
          tR.length === 1
        ) {
          return {
            ...state,
            ...stepReturnObject,
            tF: null,
            bF: null,
            c: null,
            ntxDisable: true,
            prevDisable: false,
          };
        } else if (
          state.explanationCount === state.explanations.length - 1 &&
          state.explanationsPageCount ===
            state.explanations[state.explanationCount] &&
          bR.length === 1
        ) {
          return {
            ...state,
            ...stepReturnObject,
            tF: null,
            bF: null,
            c: null,
            ntxDisable: true,
            prevDisable: false,
          };
        }
      } else {
        let size = Object.keys(state.products).length;
        let add = 0;
        for (let i = 0; i < size; i++) {
          if (state.products[i][state.fC] !== undefined) {
            add = add + state.products[i][state.fC];
          }
        }
        add = add + state.finallResultCarry;
        let fR = [...state.finallResults];
        if (state.fC === bR.length * 2 + 1) {
          fR.push(add % 10 ? add % 10 : null);
        } else {
          fR.push(add % 10);
        }
        state.finallResultCarry = Math.floor(add / 10);
        let fRC = [...state.finallResultCarries];
        fRC.push(state.finallResultCarry);
        stepReturnObject = {
          finallResultCarries: fRC,
        };
        state.fC = state.fC + 1;
        state.finallResults = fR;
        if (
          state.finallResults.length ===
          action.resultDigits.finalResult.length + 1
        ) {
          return {
            ...state,
            finallResultCarries: fRC,
            ntxDisable: true,
          };
        }
      }

      return {
        ...state,
        prevDisable: false,
        tutorialCount: state.tutorialCount + 1,
        ...stepReturnObject,
      };
    } else {
      const checkAllTheCarryArray = [];
      action.resultDigits.carries.forEach((cA) => {
        checkAllTheCarryArray.push([...new Set(cA)].length);
      });
      if (
        [...new Set(checkAllTheCarryArray)].length === 1 &&
        state.tutorialCount === 9
      ) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: 1,
        });
      }
      if (
        Number(action.resultDigits.bR.join("")) <=
          Number(action.resultDigits.tR.join("")) &&
        state.tutorialCount === 0 &&
        !state.udc
      ) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: 1,
        });
      }
      if (
        getLength(action.resultDigits.tR) === 2 &&
        state.tutorialCount === 5
      ) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: 1,
        });
      }
      if (
        getLength(action.resultDigits.tR) === 1 &&
        state.tutorialCount === 4
      ) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: 2,
        });
      }
      if (getLength(action.resultDigits.bR) === 1) {
        if (state.tutorialCount === 6) {
          return {
            ...state,
            tutorialCount: state.tutorialCount + 5,
          };
        } else if (state.tutorialCount === 2) {
          action.asyncDispatch({
            type: actionTypes.TUTORIALFORWARDER,
            countTime: 1,
          });
        } else if (
          state.tutorialCount === 4 &&
          getLength(action.resultDigits.tR) === 1
        ) {
          action.asyncDispatch({
            type: actionTypes.TUTORIALFORWARDER,
            countTime: 6,
          });
        } else if (
          state.tutorialCount === 5 &&
          getLength(action.resultDigits.tR) === 2
        ) {
          action.asyncDispatch({
            type: actionTypes.TUTORIALFORWARDER,
            countTime: 4,
          });
        }
      }
      if (state.tutorialCount === 11) {
        state.goingPrev = false;
      }
      if (state.tutorialCount === 12) {
        if (state.brnp === bR.length - 1 && state.trnp === tR.length - 1) {
          return {
            ...state,
            tutorialCount: state.tutorialCount + 1,
          };
        }
        if (state.trnp === tR.length - 1) {
          if (!state.goingPrev) {
            state.trnp = 0;
            state.brnp = state.brnp + 1;
            return {
              ...state,
            };
          } else {
            state.goingPrev = false;
            return {
              ...state,
            };
          }
        } else {
          if (!state.goingPrev) {
            state.trnp = state.trnp + 1;
            return {
              ...state,
            };
          } else {
            state.goingPrev = false;
            return {
              ...state,
            };
          }
        }
      } else if (state.tutorialCount === 13) {
        return {
          ...state,
          tutorial: false,
          prevDisable: true,
          tutorialCount: state.tutorialCount + 1,
        };
      } else {
        return {
          ...state,
          prevDisable: false,
          tutorialCount: state.tutorialCount + 1,
        };
      }
    }
  }

  if (action.type === actionTypes.PREV) {
    state.goingForward = false;
    let finalReturnObj;
    let tR = action.resultDigits.tR.filter((c) => typeof c === "number");
    let bR = action.resultDigits.bR.filter((c) => typeof c === "number");
    state.carry = 0;
    if (!state.tutorial) {
      if (!state.finallResultCount) {
        function removeProduct(p) {
          state.productArray = [...state.products[p]];
          if (tR.length === 1) {
            state.productArray = [];
          } else if (state.t === tR.length - 1) {
            state.productArray.splice(state.productArray.length - 1, 1);
          } else {
            let spliceN;
            if (state.deleteTwo) {
              spliceN = 2;
              state.deleteTwo = false;
            } else {
              spliceN = 1;
            }
            state.productArray.splice(
              state.productArray.length - spliceN,
              spliceN
            );
          }
          let products = {
            ...state.products,
            [p]: state.productArray,
          };
          return { products };
        }

        if (!state.zero) {
          if (
            state.explanationCount === 1 &&
            state.explanationsPageCount === 3 &&
            tR.length === 2
          ) {
            finalReturnObj = {
              c: 1,
            };
          } else if (
            state.explanationCount === 0 &&
            state.explanationsPageCount === 1
          ) {
            finalReturnObj = {
              c: null,
              ...removeProduct(state.p),
            };
          } else if (
            state.explanationCount === 1 &&
            state.explanationsPageCount === 1
          ) {
            finalReturnObj = {
              ...removeProduct(state.p),
            };
          } else if (
            state.explanationCount === 2 &&
            state.explanationsPageCount === 1
          ) {
            finalReturnObj = {
              ...removeProduct(state.p),
            };
          } else if (
            state.explanationCount === 1 &&
            state.explanationsPageCount === 2
          ) {
            finalReturnObj = {
              cp: state.cp - 1,
              c: 0,
            };
          } else if (
            state.explanationCount === 2 &&
            state.explanationsPageCount === 3
          ) {
            finalReturnObj = {
              c: 1,
            };
          }
        } else if (state.explanationsZeroCount === 0) {
          let products;
          state.productArray = [];
          products = {
            ...state.products,
            [state.p]: state.productArray,
          };
          state.p = state.p - 1;
          if (tR.length === 2) {
            state.cp = state.cpsr2[state.p];
          } else {
            state.cp = state.cpsr[state.p];
          }
          finalReturnObj = {
            products,
          };
        }

        if (!state.zero) {
          if (
            state.explanationCount === 0 &&
            state.b === bR.length - 1 &&
            state.explanationsPageCount === 1
          ) {
            state.prevDisable = true;
            state.cp = 0;
          }
          if (state.explanationsPageCount === 0) {
            if (state.explanationCount === 0 && !state.prevDisable) {
              state.zero = true;
              state.explanationsZeroCount = state.explanationsZeros;
            }
            if (
              state.explanationCount !== 0 &&
              !state.prevDisable &&
              !state.zero
            ) {
              state.explanationCount--;
              state.t = state.t + 1;
              state.tF = state.tF + 1;
            }
            if (!state.prevDisable) {
              state.explanationsPageCount =
                state.explanations[state.explanationCount];
            }
          } else {
            if (
              state.explanationCount === state.explanations.length - 1 &&
              state.explanationsPageCount ===
                state.explanations[state.explanationCount] &&
              bR.length === 1
            ) {
              state.tF = 3 - tR.length;
              state.bF = 2;
            }
            state.explanationsPageCount--;
          }
        } else {
          if (state.explanationsZeroCount === 0) {
            state.zero = false;
            state.deleteTwo = true;
            state.b = state.b + 1;
            state.t = 0;
            state.tF = 3 - tR.length;
            state.bF = state.bF + 1;
            state.explanationCount = state.explanations.length - 1;
            state.explanationsPageCount =
              state.explanations[state.explanationCount];
          } else {
            state.explanationsZeroCount--;
          }
        }
      } else {
        if (state.fC === 1) {
          state.finallResultCount = false;
          state.zero = false;
          state.finallResultCarries = [];
          state.finallResults = [];
          state.finallResultCarry = 0;
          state.fC = 0;
        } else if (state.fC === 0) {
          state.finallResultCount = false;
          state.zero = false;
        } else {
          state.fC = state.fC - 1;
          let fR = [...state.finallResults];
          let fRC = [...state.finallResultCarries];
          fR.splice(fR.length - 1, 1);
          fRC.splice(fRC.length - 1, 1);
          if (state.fC - 1 === 0) {
            state.finallResultCarry = 0;
          } else if (state.fC - 1 !== -1) {
            state.finallResultCarry = fRC[state.fC - 1];
          } else {
            state.finallResultCarry = 0;
          }
          finalReturnObj = {
            finallResults: fR,
            finallResultCarries: fRC,
          };
        }
      }

      return {
        ...state,
        ...finalReturnObj,
        ntxDisable: false,
        tutorialCount: state.tutorialCount - 1,
      };
    } else {
      if (state.tutorialCount === 2 && !state.udc) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: -1,
        });
      }
      if (
        getLength(action.resultDigits.tR) === 2 &&
        state.tutorialCount === 7
      ) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: -1,
        });
      }
      if (
        getLength(action.resultDigits.tR) === 1 &&
        state.tutorialCount === 7
      ) {
        action.asyncDispatch({
          type: actionTypes.TUTORIALFORWARDER,
          countTime: -2,
        });
      }

      if (getLength(action.resultDigits.bR) === 1) {
        if (state.tutorialCount === 9) {
          return {
            ...state,
            tutorialCount: state.tutorialCount - 3,
          };
        } else if (
          state.tutorialCount === 13 &&
          getLength(action.resultDigits.tR) === 1
        ) {
          action.asyncDispatch({
            type: actionTypes.TUTORIALFORWARDER,
            countTime: -8,
          });
        } else if (state.tutorialCount === 11) {
          if (getLength(action.resultDigits.tR) === 2) {
            action.asyncDispatch({
              type: actionTypes.TUTORIALFORWARDER,
              countTime: -5,
            });
          } else if (getLength(action.resultDigits.tR) === 3) {
            action.asyncDispatch({
              type: actionTypes.TUTORIALFORWARDER,
              countTime: -4,
            });
          }
        } else if (state.tutorialCount === 4) {
          action.asyncDispatch({
            type: actionTypes.TUTORIALFORWARDER,
            countTime: -1,
          });
        }
      }
      if (state.tutorialCount === 13) {
        state.goingPrev = true;
      }
      if (state.tutorialCount === 12) {
        if (state.brnp === 0 && state.trnp === 0) {
          return {
            ...state,
            tutorialCount: state.tutorialCount - 1,
          };
        }
        if (state.trnp === 0) {
          if (state.goingPrev) {
            return {
              ...state,
              trnp: tR.length - 1,
              brnp: state.brnp - 1,
            };
          } else {
            state.goingPrev = true;
            return {
              ...state,
            };
          }
        } else {
          if (state.goingPrev) {
            return {
              ...state,
              trnp: state.trnp - 1,
            };
          } else {
            state.goingPrev = true;
            return {
              ...state,
            };
          }
        }
      } else if (state.tutorialCount === 0) {
        return {
          ...state,
          prevDisable: true,
        };
      } else {
        return {
          ...state,
          tutorialCount: state.tutorialCount - 1,
        };
      }
    }
  } else {
    return state;
  }
};

export default result;
