import { Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { API, config } from "../../config";
import { useState } from "react";

const Private = () => {
  const navigate = useNavigate();
  const [checkLoggedIn, setCheckLoggedIn] = useState(true);

  (async () => {
    if (!localStorage.getItem("authToken")) {
      navigate("/login");
      return;
    }
    if (checkLoggedIn && !localStorage.getItem("authChecked")) {
      setCheckLoggedIn(false);
      try {
        await axios.get(`${API}/auth`, config());
        localStorage.setItem("authChecked", true);
      } catch (error) {
        swal(
          "Error!",
          "Your login session is over. Please log in again!",
          "error",
          {
            button: "Login",
          }
        ).then(() => {
          navigate("/login");
        });
      }
    }
  })();

  return localStorage.getItem("authToken") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default Private;
