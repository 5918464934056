export const START = "START";
export const END = "END";
export const PREV = "PREV";
export const NEXT = "NEXT";
export const ENABLEBTNS = "ENABLEBTNS";
export const DISABLEBTNS = "DISABLEBTNS";
export const UPDATEDIGITS = "UPDATEDIGITS";
export const SETPRINTDATA = "SETPRINTDATA";

export const INPUTFOCUS = "INPUTFOCUS";
export const KEYPRESS = "KEYPRESS";
export const KEYPRESSMULTI = "KEYPRESSMULTI";
export const DELETEDIGIT = "DELETEDIGIT";
export const LASTFOCUS = "LASTFOCUS";

export const KEYPADTOGGLE = "KEYPADTOGGLE";
export const KEYPADHIDE = "KEYPADHIDE";
export const KEYPADSHOW = "KEYPADSHOW";
export const SIDEMENUTOGGLE = "SIDEMENUTOGGLE";
export const SIDEMENUCLOSE = "SIDEMENUCLOSE";
export const PHEADERSTOGGLE = "PHEADERSTOGGLE";
export const GRIDTOGGLE = "GRIDTOGGLE";
export const CARRYTOGGLE = "CARRYTOGGLE";

export const BOARDSWITCH = "BOARDSWITCH";

export const PRACTICESTART = "PRACTICESTART";
export const PRACTICEEND = "PRACTICEEND";
export const PRACTICENEXT = "PRACTICENEXT";
export const PRACTICEINPUT = "PRACTICEINPUT";
export const PRACTICEMESSAGE = "PRACTICEMESSAGE";
export const PCF = "PCF";
export const PRF = "PRF";

export const INCREASEGRIDSIZE = "INCREASEGRIDSIZE";
export const DECREASEGRIDSIZE = "DECREASEGRIDSIZE";

export const SETSAVEDUSERSETTINGS = "SETSAVEDUSERSETTINGS";
export const CHECKAUTH = "CHECKAUTH";
export const SETCHECKAUTH = "SETCHECKAUTH";

export const TOUCHTABLE = "TOUCHTABLE";
export const SFTTD = "SFTTD";

export const TUTORIALFORWARDER = "TUTORIALFORWARDER";
export const UPDATEDDIGITSCHECK = "UPDATEDDIGITSCHECK";
