import * as actionTypes from "../actions/actions";

const initState = {
  topRow: ["", "", ""],
  bottomRow: ["", "", ""],
  boardTitle: {
    title: "",
    show: false,
  },
  resutltsRow: 0,
  inputDisable: false,
  btnHide: true,
  trd: 2,
  brd: 2,
  checkedAuth: false,
  welcome: true,
  oneTimesOneDigitCalculation: false,
};

const reducer = (state = { ...initState }, actions) => {
  const getLength = (r) => {
    const d = [];
    r.forEach((c) => {
      if (Number(c)) d.push(c);
    });
    return d.length;
  };

  const getPosition = (p) => {
    if (p === "topRow") {
      return state.topRow;
    } else if (p === "bottomRow") {
      return state.bottomRow;
    }
  };

  const startingObject = (top, bottom) => {
    const t = top.join("");
    const b = bottom.join("");

    return {
      boardTitle: {
        title: `We will multiply <span>${top[0]}${top[1]}${
          top[2]
        }</span> x <span>${bottom[0]}${bottom[1]}${
          bottom[2]
        }</span>. The answer is <span>${
          t * b
        }</span>, but that doesn’t matter. We don’t care about the answer here; we care only about how to get it. <span>Click Next</span>`,
        show: true,
      },
      resutltsRow: b.length,
      inputDisable: true,
      btnHide: false,
      welcome: false,
    };
  };

  const endingObject = {
    trd: getLength(state.topRow),
    brd: getLength(state.bottomRow),
  };

  switch (actions.type) {
    case actionTypes.CHECKAUTH:
      return {
        ...state,
        checkedAuth: true,
      };
    case actionTypes.SETCHECKAUTH:
      return {
        ...state,
        checkedAuth: false,
      };
    case actionTypes.BOARDSWITCH:
      return {
        ...state,
        topRow: ["", "", ""],
        bottomRow: ["", "", ""],
      };
    case actionTypes.UPDATEDIGITS:
      return {
        ...state,
        topRow: actions.topRow,
        bottomRow: actions.bottomRow,
      };
    case actionTypes.KEYPRESS:
      let p = [...getPosition(actions.position)];
      p.shift();
      p.push(actions.key);
      return {
        ...state,
        [actions.position]: p,
      };
    case actionTypes.DELETEDIGIT:
      let pDel = [...getPosition(actions.position)];
      pDel.pop();
      pDel.unshift("");
      return {
        ...state,
        [actions.position]: pDel,
      };
    case actionTypes.START:
      const startingObjectInner = {
        ...state,
        topRow: actions.topRow,
        bottomRow: actions.bottomRow,
        ...startingObject(actions.topRow, actions.bottomRow),
      };
      if (
        getLength(actions.topRow) === 1 &&
        getLength(actions.bottomRow) === 1
      ) {
        return {
          ...startingObjectInner,
          oneTimesOneDigitCalculation: true,
        };
      } else {
        return {
          ...startingObjectInner,
        };
      }
    case actionTypes.END:
      return {
        ...initState,
        ...endingObject,
        checkedAuth: state.checkedAuth,
      };
    case actionTypes.PRACTICEEND:
      return {
        ...initState,
        ...endingObject,
        checkedAuth: state.checkedAuth,
      };
    case actionTypes.PRACTICESTART:
      return {
        ...state,
        topRow: actions.topRow,
        bottomRow: actions.bottomRow,
        ...startingObject(actions.topRow, actions.bottomRow),
      };
    default:
      return state;
  }
};

export default reducer;
