import * as actionTypes from "./actions";

export const startMultiplication = (topRow, bottomRow) => {
  return {
    type: actionTypes.START,
    topRow: topRow,
    bottomRow: bottomRow,
  };
};
export const updateDigits = (topRow, bottomRow) => {
  return {
    type: actionTypes.UPDATEDIGITS,
    topRow: topRow,
    bottomRow: bottomRow,
  };
};
export const endMultiplication = () => {
  return {
    type: actionTypes.END,
  };
};

export const goPrev = (resultDigits) => {
  return {
    type: actionTypes.PREV,
    resultDigits: resultDigits,
  };
};
export const goNext = (resultDigits) => {
  return {
    type: actionTypes.NEXT,
    resultDigits: resultDigits,
  };
};

export const keypress = (key, changeFn) => {
  return {
    type: actionTypes.KEYPRESS,
    key: key,
    position: changeFn,
  };
};
export const multiply = () => {
  return {
    type: actionTypes.KEYPRESSMULTI,
  };
};
export const deleteDigit = (position) => {
  return {
    type: actionTypes.DELETEDIGIT,
    position: position,
  };
};
export const checkAuth = () => {
  return {
    type: actionTypes.CHECKAUTH,
  };
};
export const setCheckAuth = () => {
  return {
    type: actionTypes.SETCHECKAUTH,
  };
};
export const enableBtns = () => {
  return {
    type: actionTypes.ENABLEBTNS,
  };
};
export const disableBtns = () => {
  return {
    type: actionTypes.DISABLEBTNS,
  };
};
export const setPrintData = (data) => {
  return {
    type: actionTypes.SETPRINTDATA,
    data: data,
  };
};
export const setFirstTimeTwoDigit = (data) => {
  return {
    type: actionTypes.SFTTD,
    data: data,
  };
};
export const updatedDigitsCheck = () => {
  return {
    type: actionTypes.UPDATEDDIGITSCHECK,
  };
};
